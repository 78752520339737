
import { useEffect, useState } from 'preact/hooks';
import { ctx } from '.';
import { html } from 'htm/preact/index.js';
import satherm from "./assets/logo_satherm_weiss.svg";
import { ReactNode } from 'preact/compat';
import { route } from 'preact-router';
import NewHeader from './header';
import headerImg from "./assets/img/5D3_4843licht.jpg";

const Home = ({ children, imgUrl = headerImg, withList = false, withoutImg = false, reducedHeightImg = false } : {children : ReactNode, imgUrl : string , withList : boolean, withoutImg : boolean, reducedHeightImg : boolean }) => {

    const [isntHomePage, setIsntHomePage] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
        setIsntHomePage(window.location.pathname === '/' || window.location.pathname === '/contact');
    }, [])
    const { state, dispatch } = ctx();

    const [bgPosition, setBgPosition] = useState(0); // Initial background position y

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const parallaxSpeed = 0.5; // Adjust this value for slower or faster parallax effect
            const newBgPosition = -scrollY * parallaxSpeed; // Background moves at a slower rate than scrolling
            setBgPosition(newBgPosition); // Update state with the new background position
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    const handleSearch = (ev) => {
        dispatch({ type: 'search', value: ev.target.value })
        if (isntHomePage) {
            if (ev.target.value.length !== 0) {
                route("/offers")
            }
        }
    }


    return html`
    <div>
        <${NewHeader} />
        <div class="line"></div>
        <div class="p-1">

            <div class="input-group input-group-merge">
                <input value=${state.search} onkeyup=${ev => handleSearch(ev)} type="text" class="form-control search-product" id="shop-search"
                    placeholder="Recherche..." aria-label="Recherche..." aria-describedby="shop-search" />
                <span class="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search text-muted">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg></span>
            </div>
        </div>
        <div class="text-center bg-primary p-1" style="margin-bottom: 6px;">
            <h5 class="mb-0 text-white fw-bold">SATHERM: Fournisseur de piéces de rechanges pour la maintenance industrielle</h5>
        </div>
        ${
            !withoutImg ?
            html`<div class="bg-landing" style=${`background-position: 50% ${bgPosition}px; background-image:url(${imgUrl});min-height:${reducedHeightImg ? "calc(100vh - 614px)" : "calc(100vh - 249px)" };`} >
                ${
                    withList ?
                    html`
                        <div class="container">
                            <div class="p-2 bg-blue text-white card-in-landing-img rounded-1 format_head_det ">
                                <h3 class="text-white">Achats centralisés / Approvisionnement</h3>
                                <ul  class="mt-1 list-unstyled">
                                    <li class="mb-1">Achat de pièces de rechange pour installations industrielles</li>
                                    <li class="mb-1">Large fourniture de pièces</li>
                                    <li class="mb-1">Optimisation du processus d'approvisionnement</li>
                                    <li class="mb-1">Profitez d'<strong>un seul</strong> interlocuteur</li>
                                </ul>
                            </div>
                        </div>
                    ` : null
                }
                
            </div>` : null
        }
        ${children}
        <div class="keyfacts" style="padding:0 0 100px 0; background-image:url(${require("./assets/bg_keyfacts.svg")});">
            <div class="container">
                <div class="line-keyfacts " style="background-image:url(${require("./assets/line_keyfacts.svg")});">
                    <div class="standard">
                        <p>Plus de <span class="red_bold">22 millions</span> de chiffre d'affaires</p>
                    </div>
                    <div class="standard">
                        <p>Présent depuis <span class="red_bold">1976</span> </p>
                    </div>
                    <div class="standard" style="margin-right:0;">
                        <p>Nous garantissons un service de <span class="red_bold">qualité</span> </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brown ">
            <div class="container d-flex justify-content-between py-4 text-white">
                <div >
                    <p class="weiss font15">
                        <strong>Satherm GmbH</strong>
                        <br></br>
                            Werner-von-Siemens-Straße 37
                        <br></br>
                            D-66793 Saarwellingen
                        <br></br>
                            Germany - DE
                        <br></br>
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td style="color:#fff;" class="">Téléphone :</td>
                                ${Array.from({ length: 6 }).fill(0).map((_, i) => i + 1).map((ele) => (
        html`<td key=${ele}></td>`
    ))
        }
                                <td>
                                    <a href="tel:+49683898080" class="text-white">
                                        +49 (0) 68 38 / 98 08 - 0
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style="color:#fff;" class="">E-mail :</td>
                                ${Array.from({ length: 6 }).fill(0).map((_, i) => i + 1).map((ele) => (
            html`<td key=${ele}></td>`
        ))
        }
                                <td style="color:#fff;">
                                    <a href="mailto:satherm@satherm.com" class="text-white">satherm@satherm.com</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <div class="sp_13 standard width_sp_foot">
                        <div class="weiss standard">
                            <strong >Horaires d'ouverture</strong><br></br>
                        </div>
                        <div class="standard" style="clear:both;">
                            <table class="" style="margin-top:0;">
                                <tbody>
                                    <tr>
                                        <td style="color:#fff;" class="">Lundi - Vendredi</td>
                                        ${Array.from({ length: 16 }).fill(0).map((_, i) => i + 1).map((ele) => (
            html`<td key=${ele}></td>`
        ))
        }
                                        <td style="color:#fff" class="">07h30 - 17h30</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style="width:373px;" class="d-flex align-items-center">
                    <img src=${satherm} alt="satherm" width="100%" />
                </div>
            </div>
        </div>
        <div class="bg-primary py-1 ">
            <div class="container text-white d-flex justify-content-between">
                <span >Copyright 2024 Satherm GmbH</span>
                <div class="standard_r show_nav_footer">
                    <a href="Mentions_légales" class="text-white">Mentions légales</a> | <a href="Confidentialité" class="text-white">Confidentialité</a> | <a href="Responsabilité" class="text-white">Responsabilité</a>
                </div>
            </div>
        </div>
    </div>
  `;
};

export default Home;
