import { html } from 'htm/preact';
import { render } from 'preact';
import { useContext, useEffect, useReducer, useState } from 'preact/hooks';
import { route, Router } from 'preact-router';
import { createContext } from 'preact';
import { Model, RootReducer, Product, CartItem, Action, currencySymbol, ArticlesList, Offer, OffersList, getUniqueId, getItemPrice, getCartItemPrice, searchText, getMOQ, getPackaging } from './model';
import { punchout } from './core';
import Home from './home';
import Contact from './contact';
import FilterComponent from './filter';
import { PriceOfferUpdateRequestForm, PriceUpdateRequestForm } from './form';
import Login from './protection';
import Protection from './protection';
import NewHeader from './header';

export const DEFAULT_STATE = new Model();

const StoreContext = createContext({
    state: DEFAULT_STATE,      // Your default state.
    dispatch: (action: Action) => { },       // Stubbed. Will be replaced.
});


export const ctx = () => useContext(StoreContext)



const punchoutId = window.location.search
    .substring(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
        (p, n) => {
            p[n[0]] = decodeURIComponent(n[1]);
            return p;
        },
        {} as any,
    ).punchout || localStorage.getItem('punchoutId');

if (punchoutId) {
    localStorage.setItem('punchoutId', punchoutId);
}


function leadTime(product: Product) {
    if (!product.lead_time) return '';
    let weeks = Math.ceil(product.lead_time / 7);
    return weeks == 1 ? '1 semaine' : `${weeks} semaines`;
}

function App() {

    const [state, dispatch] = useReducer(RootReducer, DEFAULT_STATE);
    const store = { state, dispatch };

    useEffect(() => {
        dispatch({ type: 'products', products: ArticlesList });

    }, []);


    const [isntHomePage, setIsntHomePage] = useState(false);

    const handleRouteChange = (e) => {
        setIsntHomePage(e.url !== '/' && e.url !== '/contact' && e.url !== '/protected');
        if (!window.localStorage.getItem("loggedin")) {
            route("/protected")
        }
    };

    useEffect(() => {
        if (!window.localStorage.getItem("loggedin")) {
            route("/protected")
        }
    }, [])

    if (!punchoutId && false) {
        return html`Catalog only available for punchout`;
    } else {
        return html`<${StoreContext.Provider} value=${store}>
            <!-- ${isntHomePage ? html`<${Header} />` : null} -->
            
            <${Router} onChange=${handleRouteChange}>
                
                <${Home} path="/" withList=${true} />
                <${Contact} path="/contact"  />
                <${Main} path="/offres" />
                <${Articles} path="/articles" />
                <${PriceUpdateRequestForm} path="/price-update/:id" />
                <${PriceOfferUpdateRequestForm} path="/price-offer-update/:id" />
                <${CartView} path="/cart" />
                <${ProductDetail} path="/p/:sku" />
                <${OfferDetail} path="/offer/:sku" />
                <${Login} path="/login/:punchout_id" />
                <${Protection} path="/protected"  />
            </${Router}>
        </${StoreContext.Provider}>`;
    }

}

import OfferImg from "./assets/img/5D3_4607_Kopie.jpg";

function Main() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return html`<${Home} imgUrl=${OfferImg} withoutImg=${false} reducedHeightImg=${true}>
            <div class="app-content content ecommerce-application" style="display: flex;flex-direction: row-reverse; padding-top: 10px !important; ">
                <${Content}></${Content}>
                <${Sidebar}></${Sidebar}>
            </div>
        </<${Home}>
    `;
}


import ArticleImg from "./assets/img/5D3_4747_Kopie.jpg";
function Articles() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return html`
        <${Home} imgUrl=${ArticleImg} withoutImg=${false} reducedHeightImg=${true}>
            <div class="app-content content ecommerce-application pt-0" style="display: flex;flex-direction: row-reverse; padding-top: 10px !important; ">
                <${Content} type="articles"></${Content}>
                <${Sidebar}></${Sidebar}>
            </div>;
        </${Home}>
    `

}


function convertDateFormat(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${month}.${day}.${year}`;
}

function Sidebar() {

    const { state, dispatch } = ctx()


    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name == "request_date") {
            value = convertDateFormat(value)
        }
        dispatch({
            type: 'filter.add',
            key: name,
            value: value || null,  // Send null if no value to trigger removal
        });
    };



    const brands = [];// Object.keys(state.products.reduce((p, n) => { p[n.brand] = true; return p; }, {}));

    const categories = [];// Object.keys(state.products.reduce((p, n) => { p[n.category] = true; return p; }, {}));

    return html`<div class="sidebar-detached sidebar-left">
    <div class="sidebar">
        <!-- Ecommerce Sidebar Starts -->
        <div class="sidebar-shop">
            <!-- <div class="row">
                <div class="col-sm-12">
                    <h6 class="filter-heading d-none d-lg-block">Filtres</h6>
                </div>
            </div> -->
            <div class="card pt-0">
                <div class="card-body">
                    <!-- Price Filter starts -
                    <div class="multi-range-price">
                        <h6 class="filter-title mt-0">Multi Range</h6>
                        <ul class="list-unstyled price-range" id="price-range">
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceAll" name="price-range" class="form-check-input"
                                        checked="" />
                                    <label class="form-check-label" for="priceAll">All</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange1" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange1">&lt;=$10</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange2" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange2">$10 - $100</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceARange3" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceARange3">$100 - $500</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange4" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange4">&gt;= $500</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Price Filter ends -->

                    <!-- Price Slider starts 
                    <div class="price-slider">
                        <h6 class="filter-title">Price Range</h6>
                        <div class="price-slider">
                            <div class="range-slider mt-2 noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"
                                id="price-slider">
                                <div class="noUi-base">
                                    <div class="noUi-connects">
                                        <div class="noUi-connect"
                                            style="transform: translate(29.2786%, 0px) scale(0.404122, 1);">
                                        </div>
                                    </div>
                                    <div class="noUi-origin" style="transform: translate(-707.214%, 0px); z-index: 5;">
                                        <div class="noUi-handle noUi-handle-lower" data-handle="0" tabindex="0"
                                            role="slider" aria-orientation="horizontal" aria-valuemin="51.0"
                                            aria-valuemax="3500.0" aria-valuenow="1500.0" aria-valuetext="1500">
                                            <div class="noUi-touch-area"></div>
                                            <div class="noUi-tooltip">1500</div>
                                        </div>
                                    </div>
                                    <div class="noUi-origin" style="transform: translate(-303.092%, 0px); z-index: 4;">
                                        <div class="noUi-handle noUi-handle-upper" data-handle="1" tabindex="0"
                                            role="slider" aria-orientation="horizontal" aria-valuemin="1500.0"
                                            aria-valuemax="5000.0" aria-valuenow="3500.0" aria-valuetext="3500">
                                            <div class="noUi-touch-area"></div>
                                            <div class="noUi-tooltip">3500</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Price Range ends -->
                    <div id="clear-filters">
                        <button onclick=${() => dispatch({ type: 'filter.clear' })} type="button" class="btn w-100 btn-primary waves-effect waves-float waves-light">
                        Effacer tous les filtres</button>
                    </div>
                    <!-- Categories Starts -->
                    <div id="product-categories">
                        <h6 class="filter-title">Date de l'offre</h6>
                        <div>
                            <label htmlFor="date" className="form-label">à partir de</label>
                            <input
                            type="date"
                            className="form-control"
                            id="date"
                            name="request_date"
                            value=${state.filter.offer_date_from || ''}
                            onChange=${ev => dispatch({ type: 'filter', filter: { ...state.filter, offer_date_from: ev.target.value } })}
                            />
                        </div>
*                        <div>
                            <label htmlFor="date" className="form-label">jusqu'au</label>
                            <input
                            type="date"
                            className="form-control"
                            id="date"
                            name="request_date"
                            value=${state.filter.offer_date_to || ''}
                            onChange=${ev => dispatch({ type: 'filter', filter: { ...state.filter, offer_date_to: ev.target.value } })}
                            />
                        </div>
                    </div>
                    <!--<div id="product-categories">
                        <h6 class="filter-title">Categories</h6>
                        <ul class="list-unstyled categories-list">
                        <li onclick=${ev => dispatch({ type: 'filter', filter: { ...state.filter, category: undefined } })}>
                                <div class="form-check"  >
                                    <input type="radio" id="category-all" name="category-filter" class="form-check-input"
                                        checked=${state.filter.category == undefined} />
                                    <label class="form-check-label" for="category1">All</label>
                                </div>
                            </li>
                            ${categories.map(category => html`<li onclick=${ev => dispatch({ type: 'filter', filter: { ...state.filter, category } })}>
                                <div class="form-check"  >
                                    <input type="radio" id="category-${category}" name="category-filter" class="form-check-input"
                                        checked=${state.filter.category == category} />
                                    <label class="form-check-label" for="category1">${category}</label>
                                </div>
                            </li>`)}
                        </ul>
                    </div>-->
                    <!-- Categories Ends -->

                    <!-- Brands starts -->
                    <!-- <div class="brands">
                        <h6 class="filter-title">Numéro Client</h6>
                        <ul class="list-unstyled brand-list">
                            ${Array.from(new Set(OffersList.map(it => it.customer_number))).map((customer_number, index) => html`
                            <li>
                                <div class="form-check">
                                    <input  onchange=${ev => dispatch({ type: 'filter.in', key: 'customer_number', option: customer_number, value: ev.target.checked })} type="checkbox" class="form-check-input" id="productBrand${index}" />
                                    <label class="form-check-label" for="productBrand${index}">${customer_number}</label>
                                </div>
                                <span></span>
                            </li>`)}
                        </ul>
                    </div> -->
                    <div class="brands">
                        <h6 class="filter-title">Nom du Site</h6>
                        <ul class="list-unstyled brand-list">
                            ${Array.from(new Set(OffersList.map(it => it.customer_name))).map((customer_name, index) => html`
                            <li>
                                <div class="form-check">
                                    <input  onchange=${ev => dispatch({ type: 'filter.in', key: 'customer_name', option: customer_name, value: ev.target.checked })} type="checkbox" class="form-check-input" id="productBrand${index}" />
                                    <label class="form-check-label" for="productBrand${index}">${customer_name}</label>
                                </div>
                                <span></span>
                            </li>`)}
                        </ul>
                    </div>
                    <div class="brands">
                        <h6 class="filter-title">Demandeur</h6>
                        <ul class="list-unstyled brand-list">
                            ${Array.from(new Set(OffersList.map(it => it.contact))).map((contact, index) => html`
                            <li>
                                <div class="form-check">
                                    <input  onchange=${ev => dispatch({ type: 'filter.in', key: 'contact', option: contact, value: ev.target.checked })} type="checkbox" class="form-check-input" id="productBrand${index}" />
                                    <label class="form-check-label" for="productBrand${index}">${contact}</label>
                                </div>
                                <span></span>
                            </li>`)}
                        </ul>
                    </div>
                    <!-- Brand ends -->

                    <!-- Clear Filters Starts -->
                    
                    <!-- Clear Filters Ends -->
                </div>
            </div>
        </div>
        <!-- Ecommerce Sidebar Ends -->

    </div>
</div>`;
}
function Content({ type }: { type: 'offers' | 'articles' }) {

    type = type || 'offers';

    const { state, dispatch } = ctx();


    const articles: Product[] = searchText(state.search, state.filter, ArticlesList);

    const offers: Offer[] = searchText(state.search, state.filter, OffersList);

    return html`<div class="content-detached content-right">
    <div class="content-body">
        <!-- E-commerce Content Section Starts -->
        <section id="ecommerce-header">
            <div class="row">
                <div class="col-sm-12">
                    <div class="ecommerce-header-items">
                        <div class="result-toggler">
                            <button class="navbar-toggler shop-sidebar-toggler" type="button" data-bs-toggle="collapse">
                                <span class="navbar-toggler-icon d-block d-lg-none"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-menu">
                                        <line x1="3" y1="12" x2="21" y2="12"></line>
                                        <line x1="3" y1="6" x2="21" y2="6"></line>
                                        <line x1="3" y1="18" x2="21" y2="18"></line>
                                    </svg></span>
                            </button>
                        </div>
                        <div class="view-options d-flex">
                            <!--
                            <div class="btn-group dropdown-sort">
                                <button type="button" class="btn btn-outline-primary dropdown-toggle me-1 waves-effect"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="active-sorting">Featured</span>
                                </button>
                                <div class="dropdown-menu" style="">
                                    <a class="dropdown-item" href="#">Featured</a>
                                    <a class="dropdown-item" href="#">Lowest</a>
                                    <a class="dropdown-item" href="#">Highest</a>
                                </div>
                            </div>
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" name="radio_options" id="radio_option1"
                                    autocomplete="off" checked="" />
                                <label class="btn btn-icon btn-outline-primary view-btn grid-view-btn waves-effect"
                                    for="radio_option1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-grid font-medium-3">
                                        <rect x="3" y="3" width="7" height="7"></rect>
                                        <rect x="14" y="3" width="7" height="7"></rect>
                                        <rect x="14" y="14" width="7" height="7"></rect>
                                        <rect x="3" y="14" width="7" height="7"></rect>
                                    </svg></label>
                                <input type="radio" class="btn-check" name="radio_options" id="radio_option2"
                                    autocomplete="off" />
                                <label class="btn btn-icon btn-outline-primary view-btn list-view-btn waves-effect"
                                    for="radio_option2"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-list font-medium-3">
                                        <line x1="8" y1="6" x2="21" y2="6"></line>
                                        <line x1="8" y1="12" x2="21" y2="12"></line>
                                        <line x1="8" y1="18" x2="21" y2="18"></line>
                                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                    </svg></label>
                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- E-commerce Content Section Starts -->

        <!-- background Overlay when sidebar is shown  starts-->
        <div class="body-content-overlay"></div>
        <!-- background Overlay when sidebar is shown  ends-->

        <!-- E-commerce Search Bar Starts -->
       <!--  <section id="ecommerce-searchbar" class="ecommerce-searchbar">
            <div class="row mt-1">
                <div class="col-sm-12">
                    <div class="input-group input-group-merge">
                        <input value=${state.search} onkeyup=${ev => dispatch({ type: 'search', value: ev.target.value })} type="text" class="form-control search-product" id="shop-search"
                            placeholder="Recherche..." aria-label="Recherche..." aria-describedby="shop-search" />
                        <span class="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-search text-muted">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg></span>
                    </div>
                    
                </div>

            </div>
        </section> -->
        <!-- E-commerce Search Bar Ends -->

        <!-- E-commerce Products Starts -->
        <br/>
        ${type == 'offers' ? html`<div>
            <h4>Offres (${offers.length})</h4>
            <!--<${FilterComponent} />-->
            <!-- add Filter Section -->
        </div>
        <section id="ecommerce-products" class="grid-view">
            ${offers.map(it => OfferItem(it as any))}
        </section>` : html`
        <h4>Articles (${articles.length})</h4>
        <section id="ecommerce-products" class="grid-view">
            ${articles.map(ProductCart)}
        </section>`}
        <!-- E-commerce Products Ends -->

        <!-- E-commerce Pagination Starts
        <section id="ecommerce-pagination">
            <div class="row">
                <div class="col-sm-12">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center mt-2">
                            <li class="page-item prev-item"><a class="page-link" href="#"></a></li>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item" aria-current="page"><a class="page-link" href="#">4</a></li>
                            <li class="page-item"><a class="page-link" href="#">5</a></li>
                            <li class="page-item"><a class="page-link" href="#">6</a></li>
                            <li class="page-item"><a class="page-link" href="#">7</a></li>
                            <li class="page-item next-item"><a class="page-link" href="#"></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section> -->
        <!-- E-commerce Pagination Ends -->

    </div>
</div>`;
}


function Header() {
    const { state } = ctx();



    return html`<nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" style="height:70px"
    data-nav="brand-center">
    <div class="navbar-header d-xl-block d-none">
        <ul style="width:100%;text-align:center" class="nav navbar-nav">
            <li style="width:100%" class="nav-item"><a href="/">
                    <img style="width:239px;height:53px" src=${require("/assets/SathermLogo_CMYK.png")} alt="Satherm Logo" class="logo" />

                    </a>

                    </li>
        </ul>
        <div style="font-weight:bold;font-size:small">Satherm eBuy Catalog Solution: Empowering Sanofi's Procurement Process</div>
    </div>
    <div class="navbar-container d-flex content">

        <ul class="nav navbar-nav align-items-center ms-auto">

                <ul class=" d-flex justify-content-center align-items-end " style="margin-right:30px">
                        <a class="nav-link  text-black fw-bold  custom-nav-link" href="/offers">offers</a>
                        <a class="nav-link  text-black fw-bold  custom-nav-link" href="/articles">Articles</a>
                        <a class="nav-link  text-black fw-bold  custom-nav-link" href="/contact">Contact</a>
                </ul>

            <li class="nav-item dropdown dropdown-cart me-25">
                <a class="nav-link ${state.cartClass}" href="/cart"
                    data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-shopping-cart ficon">
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg><span
                        class="badge rounded-pill bg-primary badge-up cart-item-count">${state.cart.items.reduce((p, n) => p + n.quantity, 0)}</span></a>

            </li>


        </ul>
    </div>
</nav>` ;
}


function ProductCart(product: Product) {


    const isExpired = isPriceExpired(product);



    console.log(product);



    return html`<div class="card ecommerce-card">
    <div class="item-img text-center">
        <a href="/offer/${product.offer_number}">
        <${ItemDescriptionSmall} item=${product} />
            <!--<img class="img-fluid card-img-top" src=${product.search_name} alt="img-placeholder" />--></a>
    </div>
    <div class="card-body">
       <div class="item-wrapper">
            <div>
                
                ${!getItemPrice(product, 1) ? html`<h6 class="item-price">No Price</h6>` : html`<h6 class="item-price">${getItemPrice(product, 1)?.toFixed(2)} ${currencySymbol(product.currency)} ${product.unit} ${isExpired ? '(Expiré)' : ''}</h6>`
        }
            </div>
        </div>
        <!--<h6 class="item-name">
            <a class="text-body" href="/offer/${product.offer_number}">${product.description}</a>
            <span class="card-text item-company">By <a href="#" class="company-name">${product.sku}</a></span>
        </h6>
        <p class="card-text item-description">${product.description}</p>-->
    </div>
    <div class="item-options text-center">
        ${getItemPrice(product, 1) && !isExpired ? html`<${AddToCartBtn} product=${product}></${AddToCartBtn}> ` : html`
                <a href="/price-update/${encodeURIComponent(product.sku)}" class="btn btn-primary btn-cart waves-effect waves-float waves-light" style="background: #021e44 !important; border-color: #021e44 !important;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#fff" d="M290.7 93.2l128 128-278 278-114.1 12.6C11.4 513.5-1.6 500.6 .1 485.3l12.7-114.2 277.9-277.9zm207.2-19.1l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"/></svg>
                    <span class="add-to-cart" style="padding-left:4px;">Demander le prix</span>
                </a>
            `
        }
    </div>
</div>`;
}

function AddToCartBtn({ product }: { product: Product }) {
    const { dispatch } = ctx();
    return html`<a onclick=${() => {
        dispatch({ type: 'add', product, quantity: 1 });
        setTimeout(() => dispatch({ type: 'endanimate' }), 700);
    }} class="btn btn-primary btn-cart waves-effect waves-float waves-light">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart">
        <circle cx="9" cy="21" r="1"></circle>
        <circle cx="20" cy="21" r="1"></circle>
        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
    </svg>
    <span class="add-to-cart">Ajouter au panier</span>
</a>`;
}
function WhishlistBtn() {
    return html`<a href="#" class="btn btn-light btn-wishlist waves-effect waves-float waves-light">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart">
        <path
            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
        </path>
    </svg>
    <span>Wishlist</span>
</a>`;
}


import CardImg from "./assets/img/5D3_4810_final.jpg";
function CartView() {

    const { state } = ctx();

    const onPunchoutClick = () => {
        if (!localStorage.getItem('punchout_id')) {
            return alert('You are not in a punchout session. Please start a session from your procurement system.');
        }
        punchout.submitCart({
            items: state.cart.items.map(it => ({ ...it, product: { ...it.product, price: Math.round(getCartItemPrice(it) * 100) } })),
            currency: state.cart.items[0]?.product.currency,
        })
            .then(() => { });
    };

    return html`
        <${Home} imgUrl=${CardImg} reducedHeightImg=${true} >
    <div class="app-content content ecommerce-application" style="padding-top: 10px !important;">
    
        <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
    <div class="bs-stepper checkout-tab-steps">
        <div class="bs-stepper-content">
            <!-- Checkout Place order starts -->
            <div id="step-cart" class="content active dstepper-block" role="tabpanel" aria-labelledby="step-cart-trigger">
                <div id="place-order" class="list-view product-checkout">
                    <!-- Checkout Place Order Left starts -->
                    <div class="checkout-items">
                        ${state.cart.items.map(CartItemView)}

                    </div>
                    <!-- Checkout Place Order Left ends -->

                    <!-- Checkout Place Order Right starts -->
                    <div class="checkout-options">
                        <div class="card">
                            <div class="card-body">
                                <!--
                                <label class="section-label form-label mb-1">Options</label>
                                <div class="coupons input-group input-group-merge">
                                    <input type="text" class="form-control" placeholder="Coupons" aria-label="Coupons" aria-describedby="input-coupons"/>
                                    <span class="input-group-text text-primary ps-1" id="input-coupons">Apply</span>
                                </div>
                                <hr/>
-->
                                <div class="price-details">
                                    <!--
                                    <h6 class="price-title">Price Details</h6>
                                    <ul class="list-unstyled">
                                        <li class="price-detail">
                                            <div class="detail-title">Total MRP</div>
                                            <div class="detail-amt">$598</div>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">Bag Discount</div>
                                            <div class="detail-amt discount-amt text-success">-25$</div>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">Estimated Tax</div>
                                            <div class="detail-amt">$1.3</div>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">EMI Eligibility</div>
                                            <a href="#" class="detail-amt text-primary">Details</a>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">Delivery Charges</div>
                                            <div class="detail-amt discount-amt text-success">Free</div>
                                        </li>
                                    </ul>
                                    <hr/>
-->
                                    <ul class="list-unstyled">
                                        <li class="price-detail">
                                            <div class="detail-title detail-total">Total</div>
                                            <div class="detail-amt fw-bolder">${state.cart.items.reduce((p, n) => p + getCartItemPrice(n) * n.quantity, 0).toFixed(2)} ${currencySymbol(state.cart.items[0]?.product.currency)}</div>
                                        </li>
                                    </ul>
                                    <button onclick=${(ev) => {
            ev.preventDefault();
            onPunchoutClick();
        }} type="button" class="btn btn-primary w-100 btn-next place-order waves-effect waves-float waves-light">Valider la commande</button>
                                </div>
                            </div>
                        </div>
                        <!-- Checkout Place Order Right ends -->
                    </div>
                </div>
                <!-- Checkout Place order Ends -->
            </div>

        </div>
    </div>
        </div>
    </div>
</div>
</${Home}>
`;
}


function CartItemView(item: CartItem) {

    const { state, dispatch } = ctx();

    return html`<div class="card ecommerce-card">
                            <div class="item-img">
                            <div style="text-align:center">
                                <h4>Offre: ${item.product.offer_number}</h4>
                                    ${item.product.article_number}
                                    </div>
                            </div>
                            <div class="card-body">
                                <div class="item-name">
                                    <h6 class="mb-0"><a class="text-body">${item.product.sku}</a></h6>
                                    <span class="item-company"><a class="company-name">${item.product.description}</a></span>
                                </div>
                                <!--<span class="text-success mb-1">In Stock</span>-->
                                <div class="item-quantity">
                                    <span class="quantity-title">Qté:</span>
                                    <div class="quantity-counter-wrapper">
                                        <div class="input-group bootstrap-touchspin">
                                            <span class="input-group-btn bootstrap-touchspin-injected">
                                                <button onclick=${() => dispatch({ type: 'quantity', id: item.product.id, quantity: item.quantity - 1 })} class="btn btn-primary bootstrap-touchspin-down" type="button">-</button>
                                            </span>
                                            <input onkeyup=${ev => dispatch({ type: 'quantity', id: item.product.id, quantity: parseInt(ev.target.value) })} value=${item.quantity} type="number" class="quantity-counter form-control"/>
                                            <span onclick=${() => dispatch({ type: 'quantity', id: item.product.id, quantity: item.quantity + 1 })} class="input-group-btn bootstrap-touchspin-injected">
                                                <button class="btn btn-primary bootstrap-touchspin-up" type="button">+</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-options text-center">
                                <div class="item-wrapper">
                                    <div class="item-cost">
                                        <h4 class="item-price">${getCartItemPrice(item).toFixed(2)} ${currencySymbol(item.product.currency)} / ${item.product.price_unit > 1 ? item.product.price_unit : ''}${item.product.unit}</h4>
                                        <h5 style="margin-top:4px;margin-bottom:-4px">Total: ${(getCartItemPrice(item) * item.quantity).toFixed(2)}${currencySymbol(item.product.currency)}</h5>
                                    </div>
                                </div>
                                <button onclick=${() => dispatch({ type: 'quantity', id: item.product.id, quantity: 0 })}  type="button" class="btn btn-light mt-1 remove-wishlist waves-effect waves-float waves-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x align-middle me-25"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    <span>Supprimer</span>
                                </button>
                            </div>
                        </div>`
}

function ProductPreview(product: Product, addToCart: any) {
    return html`<div>${product.search_name} :${product.price} EA

    <button onclick=${ev => addToCart(product, 1)}>Ajouter au panier</button>
</div>`;
}
function Product({ productId }: { productId: string }) {

}

function ProductDetail({ sku }: { sku: string }) {
    const { state, dispatch } = ctx();
    console.log(state);

    const product = state.products.find(it => it.sku == sku);
    if (product) {
        return html`
    <${NewHeader} />
        <div class="content-wrapper container-xxl p-0 animate__animated animate__fadeIn">
    
    <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details" style="padding-top: 30px !important;">
        <div class="card">
            <!-- Product Details starts -->
            <div class="card-body">
                <div class="row my-2">
                    <div class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0">
                        <div class="d-flex align-items-center justify-content-center">
                            <img src=${product.search_name} class="img-fluid product-img" alt="product image"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-7">
                        <h4>${product.description}</h4>
                        <span class="card-text item-company">By <a href="#" class="company-name">${product.sku}</a></span>
                        <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                            <h4 class="item-price me-1">${product.price} ${currencySymbol(product.currency)} EA</h4>
                            <!--
                            <ul class="unstyled-list list-inline ps-1 border-start">
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star unfilled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                            </ul>
    -->
                        </div>
                        <p class="card-text">Available - <span class="text-success">In stock</span></p>
                        <p class="card-text">
                            ${product.description}
                        </p>
                        <!--
                        <ul class="product-features list-unstyled">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg> <span>Free Shipping</span></li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                <span>EMI options available</span>
                            </li>
                        </ul>
    -->
                        <hr/>
                        ${['Group', 'Item', 'Make', 'Model', 'Form Factor', 'Configuration with 3 Year Warranty']
                .map(key => html`<div class="product-color-options" style="display:flex">
                            <h6 style="width: 220px">${key}</h6>
                            <span>${product[key]}</span>
                        </div>`)
            }
                        <hr/>
                        <div class="d-flex flex-column flex-sm-row pt-1">
                            <a onclick=${() => dispatch({ type: 'add', product, quantity: 1 })} class="btn btn-primary btn-cart me-0 me-sm-1 mb-1 mb-sm-0 waves-effect waves-float waves-light">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart me-50"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                <span class="add-to-cart">Ajouter au panier</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Product Details ends -->

        </div>
    </section>
    <!-- app e-commerce details end -->

</div></div>`;
    } else {
        return html`loading`;
    }
}


function OfferItem(offer: Offer) {

    console.log('====================================');
    console.log(offer);
    console.log('====================================');

    return html`<div class="card ecommerce-card">
    <a href="/offer/${offer.offer_number}">
    ${isPriceExpired(offer) ? html`
        <a href="/price-offer-update/${offer.offer_number}" class="update-offer-price d-flex align-items-center" >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#001a46dd" d="M290.7 93.2l128 128-278 278-114.1 12.6C11.4 513.5-1.6 500.6 .1 485.3l12.7-114.2 277.9-277.9zm207.2-19.1l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"/></svg>
            <span class="add-to-cart" style="padding-left:5px;color:#001a46dd !important;">Demander le prix</span>
        </a>` : null
        } 
    
    <div class="item-img text-center">        
            <h3>OFFRE N°: <span class="red">${offer.offer_number}</span><br/>
            
            <h6>Validité offre: ${validity(offer.offer_date)}</h6>
            <h6>(${offer.items.length} ${offer.items.length > 1 ? 'articles' : 'article'})</h6>
            </h3>
            
    </div>
    <div class="card-body">
        <div class="item-wrapper">
            <div>
                <h6 class="item-price">${offer.customer_name} <br/> ${offer.contact} EA</h6>
            </div>
        </div>
        <p class="card-text item-description"> ${offer.request_data} / ${offer.request_date}</p>
    </div>
    </a>
</div>`;
}

function validity(offer_date: string) {
    offer_date = offer_date || '';
    const [day, month, year] = offer_date?.split('.').map(it => parseInt(it));
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + 180);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
}

function isPriceExpired(item: { offer_date?: string }) {
    if (!item.offer_date) return true;
    const offer_date = item.offer_date || '';
    const [day, month, year] = offer_date?.split('.').map(it => parseInt(it));
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + 180);
    return date < new Date();
}

function OfferDetail({ sku }: { sku: string }) {

    const [quantity, setQuantityRaw] = useState<{ [key: string]: number }>({});

    const setQuantity = (item: Product, value?: number, diff = 0) => {
        const key = getUniqueId(item);
        if (diff) {
            value = (quantity[key] || 0) + diff;
        }
        if (!value) value = 0;
        if (value < 0) value = 0;

        if (diff > 0 && value > 0 && value < getMOQ(item)) value = getMOQ(item);
        if (diff < 0 && value > 0 && value < getMOQ(item)) value = 0;
        setQuantityRaw({ ...quantity, [key]: value });
    };
    const offer = OffersList.find(it => it.offer_number == sku);

    console.log('====================================');
    console.log(offer);
    console.log('====================================');



    const { state, dispatch } = ctx();
    console.log(offer);
    if (offer) {

        const onAddToCartClick = () => {

            for (const item of offer.items) {
                if (quantity[item.id] && quantity[item.id] < getMOQ(item)) {
                    return alert(`La quantité minimale pour ${item.sku} est de ${getMOQ(item)}`);

                }
            }

            offer.items.forEach(item => {
                if (quantity[item.id]) {
                    dispatch({
                        type: 'add',
                        product: item,
                        quantity: quantity[item.id],
                    });
                }
            });
        };

        const getPrice = (item: Product) => getItemPrice(item, quantity[getUniqueId(item)]);

        const isExpired = isPriceExpired(offer);


        return html`<${Home} imgUrl=${CardImg} withoutImg=${true} >
        <div class="content-wrapper container-xxl p-0 animate__animated animate__fadeIn" >
    
    <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details" style="padding-top:30px !important;">
        <div class="card">
            <div class="card-body">
                <div class="row my-2">
                    <div class="col-3">
                            <h4>OFFRE N°: <b class="red">${offer.offer_number}</b></h4>
                            <h6>Date de la demande client: <br/><b>${offer.request_date}</b></h6>
                            <h6>Date de validité: <br/><b>${validity(offer.offer_date)}</b></h6>
                            <h6>Numero Client: <br/><b>${offer.customer_number}</b></h6>
                            <h6>Nom du site: <br/><b>${offer.customer_name}</b></h6>
                            <h6>Nom du demandeur: <br/><b>${offer.contact}</b></h6>
                            <h6>N° de la demande client: <br/><b>${offer.request_data}</b></h6>
                            <h6>Incoterm: <br/><b>${offer.incoterm}</b></h6>
                            <h6>Cond. de paiement: <br/><b>${offer.payment_terms}</b></h6>
                    </div>
                    <div class="col-9">
                    ${offer.items.filter(it => it.position_type == 'A').map(item => OfferItemView(item, offer, quantity, setQuantity, getPrice))}
                        <div class="d-flex flex-column flex-sm-row pt-1" style="justify-content:end">
                            ${isExpired ? html`<a href="/price-offer-update/${encodeURIComponent(offer.offer_number)}" class="btn btn-primary btn-cart waves-effect waves-float waves-light" style="background: #021e44 !important; border-color: #021e44 !important;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#fff" d="M290.7 93.2l128 128-278 278-114.1 12.6C11.4 513.5-1.6 500.6 .1 485.3l12.7-114.2 277.9-277.9zm207.2-19.1l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"/></svg>
                    <span class="add-to-cart" style="padding-left:4px;">Demander le prix</span>
                </a>`: html`<a onclick=${onAddToCartClick} class="btn btn-primary btn-cart me-0 me-sm-1 mb-1 mb-sm-0 waves-effect waves-float waves-light">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart me-50"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                <span class="add-to-cart">Ajouter au panier</span>
                            </a>`}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Product Details ends -->

        </div>
    </section>
    <!-- app e-commerce details end -->

</div></div></${Home}>`;
    } else {
        return html`loading`;
    }
}

export function ItemDescription({ item }: { item: Product }) {
    return html`<div class="col-7">
        <h4>
            ${item.customer_article_number.toUpperCase() == 'DIVERS' ? html`` : html`Code article client: <b>${item.customer_article_number}</b><br/>`}
            Référence: <b>${item.sku}</b>
        </h4>
        <p class="card-text">
            <b>Description:</b> <br/> ${item.description}
            </p>
            ${item.article_type == 'W' ? html`<div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Code Douanier</h6>
                <span>${item.goods_number}</span>
            </div>
            <div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Pays d'Origine</h6>
                <span>${item.country_of_origin}</span>
            </div>`: ''}
            ${item.lead_time ? html`<div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Délai de livraison</h6>
                <span>${leadTime(item)}</span>
            </div>`: ''}
            ${item.discount ? html`<div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Rabais</h6>
                <span>${item.discount}%</span>
            </div>`: ''}
        </div>`;

}

export function ItemDescriptionSmall({ item }: { item: Product }) {
    return html`<div>
        <h5>
            ${item.customer_article_number.toUpperCase() == 'DIVERS' ? html`` : html`Code article client: <b>${item.customer_article_number}</b><br/>`}
            Référence: <b>${item.sku}</b>
        </h5>
            <!-- <p class="card-text" style="height:90px">
             ${item.description.slice(0, 120)}${item.description.length > 120 ? '...' : ''}
            </p> -->
            ${item.article_type == 'W' ? html` <!-- <div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Code Douanier</h6>
                <span>${item.goods_number}</span>
            </div> -->
            <!--<div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Pays d'Origine</h6>
                <span>${item.country_of_origin}</span>
            </div> -->`: ''}
            ${item.lead_time ? html`<!-- <div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Délai de livraison</h6>
                <span>${leadTime(item)}</span>
            </div> -->`: ''}
            ${item.discount ? html`<div class="product-color-options" style="display:flex">
                <h6 style="width: 220px">Rabais</h6>
                <span>${item.discount}%</span>
            </div>`: ''}
        </div>`;

}

export function OfferItemView(item: Product, offer: Offer, quantity: { [key: string]: number }, setQuantity: (item: Product, value?: number, diff?: number) => void, getPrice) {
    const isExpired = isPriceExpired(offer);

    return html`<div class="row">
                        <${ItemDescription} item=${item} />
                        ${item.prices.find(it => it.price) ? html`
                        <div class="col-5 row">
                                                <div class="ecommerce-details-price d-flex flex-wrap mt-1" style="justify-content:end">
                                                
                                                ${item.alternative == 'Wahr' ? html`<h6 style="padding-right:8px">(Option Alternative)</h6>` : ''}
                            <h4 class="item-price me-1">${getPrice(item) || item.prices[0]?.price} ${currencySymbol(offer.currency)} / ${item.price_unit > 1 ? item.price_unit : ''} ${item.unit}</h4>
                    
                                    <div class="item-quantity" style="margin-top: -5px">
                                    <div class="quantity-counter-wrapper">
                                        <div class="input-group bootstrap-touchspin">
                                            <span class="input-group-btn bootstrap-touchspin-injected">
                                                <button onclick=${() => setQuantity(item, undefined, -1)} class="btn btn-primary bootstrap-touchspin-down" type="button">-</button>
                                            </span>
                                            <input onkeyup=${ev => setQuantity(item, parseInt(ev.target.value))} value=${quantity[getUniqueId(item)] || 0} type="number" class="quantity-counter form-control"/>
                                            <span onclick=${() => setQuantity(item, undefined, 1)} class="input-group-btn bootstrap-touchspin-injected">
                                                <button class="btn btn-primary bootstrap-touchspin-up" type="button">+</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        ${item.prices.length > 1 && item.volume_discount ? html`<div>
                            <table class="table border table-striped" style="font-size:small;margin-top:10px">
                            <thead>
                                <tr>
                                    ${item.prices.map(it => html`
                                        <td>${it.quantity}</td>
                                    `)}
                                </tr>
                            </thead>
                                <tbody><tr>
                                    ${item.prices.map(it => html`
                                        <td>${currencySymbol(offer.currency)}${it.price}</td>
                                    `)}</tr>
                                </tbody>
                            </table>
                        </div>` : ''}
<div style="height:100%; text-align: right;margin-top:10px">
${getMOQ(item) > 1 ? html`<h6 style="padding-right:8px">Quantité minimum : ${getMOQ(item)} ${item.unit} </h6>` : ''}
<h6 style="padding-right:8px">Conditionnement : ${getPackaging(item)} ${item.unit} </h6>
<h6 style="padding-right:8px">Prix : ${item.price_unit} ${item.unit} </h6>
${isExpired ? html`<h6 style="padding-right:8px;color:red">Prix expiré</h6>` : ''}
</div>
                        

                        </div>`: ''}
                        </div> <hr/>`;
}


render(html`<${App} />`, document.body)

